import React from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Input, Row, Select } from 'antd';
import { useFetchRoles } from '../../Hooks/Role.hook';
import { useFetchCompanies } from '../../Hooks/Companies.hook';
import { generateQueries } from '../../Utils/query';
import { SearchSelector } from '../Atoms/SearchSelector';
import { debounce } from 'lodash';

const CompanieSearch = [
  {
    name: 'description',
    type: 'input',
    whitelist: true,
  },
  {
    name: 'status',
    type: 'select',
  },
];

const RolesSearch = [
  {
    name: 'description',
    type: 'input',
    whitelist: true,
  },
  {
    name: 'status',
    type: 'select',
  },
];

export const UserForm = ({ formRef, onSubmit }) => {
  const [roles, rloading, rQuery] = useFetchRoles();
  const [companies, cloading, cQuery] = useFetchCompanies();

  const handleSearchCompanies = s => {
    if (s.length > 2) {
      let sQueries = generateQueries(
        { description: s, status: 1 },
        CompanieSearch
      );
      cQuery(sQueries, 0, 50);
    } else {
      cQuery();
    }
  };

  const handleSearchRoles = s => {
    if (s.length > 2) {
      let sQueries = generateQueries(
        { description: s, status: 1 },
        RolesSearch
      );
      rQuery(sQueries, 0, 50);
    } else {
      rQuery();
    }
  };

  if (rloading || cloading) {
    return <div>Cargando...</div>;
  }

  return (
    <Form form={formRef} layout="vertical" name="UserForm" onFinish={onSubmit}>
      <Row gutter={[12, 12]}>
        <Col span={12}>
          <Form.Item
            label="Usuario"
            name="username"
            rules={[{ required: true, message: 'Campo requerido' }]}
          >
            <Input type="text" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: 'Campo requerido' }]}
          >
            <Input type="email" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            label="Nombre"
            name="full_name"
            rules={[{ required: true, message: 'Campo requerido' }]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[12, 12]}>
        <Col span={12}>
          <Form.Item label="Contraseña" name="password">
            <Input type="password" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Repetir contraseña" name="rpassword">
            <Input type="password" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[12, 12]}>
        <Col span={24}>
          <Form.Item label="Compañia" name="company_id">
            <SearchSelector
              allowClear
              handleSearch={debounce(handleSearchCompanies, 200)}
              loading={cloading}
              onChange={() => cQuery()}
              placeholder="Seleccionar Compañia"
            >
              {companies?.data.map(
                oCompanie =>
                  oCompanie.status === 1 && (
                    <Select.Option key={oCompanie.id} value={oCompanie.id}>
                      {oCompanie.trade_name}
                    </Select.Option>
                  )
              )}
            </SearchSelector>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[12, 12]}>
        <Col span={12}>
          <Form.Item label="Estatus" name="status">
            <Select>
              <Select.Option value={0}>Inactivo</Select.Option>
              <Select.Option value={1}>Activo</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Rol" name="rol_id">
            <SearchSelector
              allowClear
              handleSearch={debounce(handleSearchRoles, 200)}
              loading={rloading}
              onChange={() => rQuery()}
              placeholder="Seleccionar Rol"
            >
              {roles?.data.map(
                oRole =>
                  oRole.status === 1 && (
                    <Select.Option key={oRole.id} value={oRole.id}>
                      {oRole.name}
                    </Select.Option>
                  )
              )}
            </SearchSelector>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

UserForm.propTypes = {
  formRef: PropTypes.object,
  onSubmit: PropTypes.func,
};
