import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import { DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import 'moment/locale/es-mx';
import locale from 'antd/es/date-picker/locale/es_ES';
import { useFetchBranchWithApiKey } from '../../Hooks/Branch.hook';
import { useFetchClients } from '../../Hooks/Client.hook';
import { generateQueries } from '../../Utils/query';
import debounce from 'lodash/debounce';
import { SearchSelector } from '../Atoms/SearchSelector';
import { useSelector } from 'react-redux';

const { RangePicker } = DatePicker;

export const SearchBar = ({
  elements,
  handleReset,
  handleSearch,
  search,
  setSearch,
  // eslint-disable-next-line no-unused-vars
  requiredFields = false,
}) => {
  const aVisibles = elements.filter(({ visible = true }) => visible);
  const nFieldsSize = Math.round(18 / aVisibles.length);
  const { role } = useSelector(state => ({ role: state.auth.role }));
  const [fieldsCompleted, setFieldsCompleted] = useState(false);
  const [branches, loading, query] =
    role?.rol === 'client'
      ? [null, false, null]
      : useFetchBranchWithApiKey('', 0, 50);

  const [clients, cLoading, cQuery] = role?.rol
    ? useFetchClients('', 0, 50)
    : [null, false, null];

  const branchSearch = [
    {
      name: 'trade_name',
      type: 'input',
    },
    {
      name: 'status',
      type: 'select',
    },
  ];
  const handleSearchBranches = s => {
    if (s.length > 2) {
      let sQueries = generateQueries(
        { trade_name: s, status: 1 },
        branchSearch
      );
      query(sQueries, 0, 50);
    } else {
      query();
    }
  };

  const handleSearchClients = s => {
    if (s.length > 2) {
      let sQueries = generateQueries(
        { trade_name: s, status: 1 },
        branchSearch
      );
      cQuery(sQueries, 0, 50);
    } else {
      cQuery();
    }
  };

  const getDateForm = ({ name, value }) => {
    setSearch({
      ...search,
      [name]: value,
    });
  };

  const handleDates = aDates => {
    if (aDates) {
      setSearch({
        ...search,
        date_ini: aDates[0],
        date_end: aDates[1],
      });
    }
  };

  const handleFieldsCompleted = () => {
    const areAllFieldsCompleted = Object.values(search).every(
      value => value !== ''
    );
    if (areAllFieldsCompleted) {
      setFieldsCompleted(true);
    } else {
      setFieldsCompleted(false);
    }
  };

  useEffect(() => {
    handleFieldsCompleted();
  }, [search]);

  return (
    <Form layout="vertical">
      <Row
        gutter={[20, 20]}
        style={{ flexDirection: 'row-reverse', justifyContent: 'start' }}
      >
        <Col>
          {requiredFields === false || fieldsCompleted === true ? (
            <Form.Item label="&nbsp;">
              <Button
                onClick={handleSearch}
                style={{ borderStyle: 'none', boxShadow: 'none' }}
              >
                <SearchOutlined />
              </Button>
            </Form.Item>
          ) : (
            <Form.Item label="&nbsp;">
              <Button
                disabled
                onClick={handleSearch}
                style={{ borderStyle: 'none', boxShadow: 'none' }}
              >
                <SearchOutlined />
              </Button>
            </Form.Item>
          )}
        </Col>
        <Col>
          <Form.Item label="&nbsp;">
            {handleReset && (
              <Button
                onClick={handleReset}
                style={{
                  borderStyle: 'none',
                  boxShadow: 'none',
                  color: '#F05249',
                  marginLeft: 10,
                }}
              >
                <DeleteOutlined />
              </Button>
            )}
          </Form.Item>
        </Col>
        {elements
          .filter(({ visible = true }) => visible)
          .reverse()
          .map(
            (
              {
                label,
                name,
                placeholder = '',
                sizes = { small: 24, normal: 5 },
                type,
                values,
              },
              nIndex
            ) => (
              <Col
                key={nIndex}
                md={sizes.normal || nFieldsSize}
                sm={sizes.small || 12}
                xs={24}
              >
                <Form.Item label={label}>
                  {type === 'input' || type === 'input-fixed' ? (
                    <Input
                      name={name}
                      onChange={({ target }) => getDateForm(target)}
                      placeholder={placeholder}
                      type="text"
                      value={search[name]}
                    />
                  ) : type === 'input-int' ? (
                    <Input
                      name={name}
                      onChange={({ target }) => getDateForm(target)}
                      placeholder={placeholder}
                      type="number"
                      value={search[name]}
                    />
                  ) : type === 'select' ? (
                    <Select
                      name={name}
                      onChange={value => getDateForm({ name, value })}
                      placeholder={placeholder}
                      style={{ width: '100%' }}
                      value={search[name]}
                    >
                      {typeof values === 'function' && values()}
                    </Select>
                  ) : type === 'branch' ? (
                    <SearchSelector
                      name={name}
                      allowClear
                      handleSearch={debounce(handleSearchBranches, 300)}
                      loading={loading}
                      onChange={value => {
                        query();
                        getDateForm({ name, value });
                      }}
                      placeholder="Seleccionar Sucursal"
                      value={search[name]}
                    >
                      {branches?.data.map(
                        oClient =>
                          oClient?.trade_name && (
                            <Select.Option key={oClient.id} value={oClient.id}>
                              {oClient.trade_name}
                            </Select.Option>
                          )
                      )}
                    </SearchSelector>
                  ) : type === 'client' ? (
                    <SearchSelector
                      name={name}
                      allowClear
                      handleSearch={debounce(handleSearchClients, 200)}
                      loading={cLoading}
                      onChange={value => {
                        cQuery();
                        getDateForm({ name, value });
                      }}
                      placeholder="Seleccionar Cliente"
                      value={search[name]}
                    >
                      {clients?.data.map(
                        oClient =>
                          oClient?.trade_name && (
                            <Select.Option
                              key={oClient._id}
                              value={oClient._id}
                            >
                              {oClient.trade_name}
                            </Select.Option>
                          )
                      )}
                    </SearchSelector>
                  ) : type === 'date' ? (
                    <RangePicker
                      locale={locale}
                      onChange={handleDates}
                      style={{ width: '100%' }}
                      value={[search?.date_ini, search?.date_end]}
                    />
                  ) : null}
                </Form.Item>
              </Col>
            )
          )}
      </Row>
    </Form>
  );
};

SearchBar.propTypes = {
  elements: PropTypes.array.isRequired,
  handleReset: PropTypes.func,
  handleSearch: PropTypes.func.isRequired,
  search: PropTypes.object.isRequired,
  setSearch: PropTypes.func.isRequired,
  requiredFields: PropTypes.bool,
};
