import React, { useState } from 'react';
import { Layout, Popconfirm, Row } from 'antd';
import {
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';
import SubRouter from './SubRouter';
import { DynamicMenu } from './DynamicMenu';
import { useAuth } from '../../Hooks';
import logo from '../../Assets/img/logo_factora_blanco.svg';

const { Content, Header, Sider } = Layout;

export const MainContainer = () => {
  const [isCollapsed, setCollapsed] = useState(false);
  const [, , logout] = useAuth();

  const toggle = () => setCollapsed(!isCollapsed);

  return (
    <>
      <Sider
        className="sider"
        collapsible
        collapsed={isCollapsed}
        trigger={null}
      >
        <div className="logo">
          <img src={logo} width="90%" height="90%" />
        </div>
        <DynamicMenu />
      </Sider>
      <Layout className="site-layout">
        <div className="wrapper">
          <Header className="site-layout-background" style={{ padding: 0 }}>
            <Row
              align="middle"
              justify="space-between"
              style={{
                marginRight: '25px',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {isCollapsed ? (
                <MenuUnfoldOutlined className="trigger" onClick={toggle} />
              ) : (
                <MenuFoldOutlined className="trigger" onClick={toggle} />
              )}
              <Popconfirm
                cancelText="Cancelar"
                okText="Si"
                onConfirm={() => logout()}
                placement="topRight"
                title="Desea cerrar sesión?"
              >
                <LogoutOutlined />
              </Popconfirm>
            </Row>
          </Header>
          <Content
            className="site-layout-background"
            style={{
              margin: '24px 16px',
              padding: 24,
            }}
          >
            <SubRouter />
          </Content>
        </div>
      </Layout>
    </>
  );
};
