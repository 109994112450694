import React, { useState } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { Col, Form, Row, Select, Input } from 'antd';
import { SearchSelector } from '../Atoms/SearchSelector';
import {
  useFetchClients,
  useFetchFormsSAT,
  useFetchMethodsPay,
} from '../../Hooks';
import { process, GET } from '../../Service/Api';
import { generateQueries } from '../../Utils/query';

const clientSearch = [
  {
    name: 'invoice_name',
    type: 'input',
    whitelist: true,
  },
  {
    name: 'status',
    type: 'select',
  },
];

const formSearch = [
  {
    name: 'description',
    type: 'input',
    whitelist: true,
  },
  {
    name: 'status',
    type: 'select',
  },
];

export const InvoiceForm = ({ formRef, onSubmit }) => {
  const [clients, loading, query] = useFetchClients(`status=1`);
  const [formspays, pLoading, fQuery] = useFetchFormsSAT();
  const [methods, mLoading] = useFetchMethodsPay();
  const [sClient, setSClient] = useState({});
  const handleSearchClients = s => {
    if (s.length > 2) {
      let sQueries = generateQueries(
        { invoice_name: s, status: 1 },
        clientSearch
      );
      query(sQueries, 0, 50);
    } else {
      query(`status=1`);
    }
  };

  const handleSearchForms = s => {
    if (s.length > 2) {
      let sQueries = generateQueries({ description: s, status: 1 }, formSearch);
      fQuery(sQueries, 0, 50);
    } else {
      fQuery();
    }
  };

  const handleEmail = async sID => {
    let oClient = await process(GET, 'clients', {}, { id: sID });
    let email = oClient.data.email;
    formRef.setFieldsValue({ email });
    setSClient(oClient);
  };

  return (
    <Form
      form={formRef}
      layout="vertical"
      name="InvoiceForm"
      onFinish={onSubmit}
    >
      <Row gutter={[12, 12]}>
        <Col span={24}>
          <Form.Item
            label="Cliente"
            name="cliente"
            rules={[{ required: true, message: 'Campo requerido' }]}
          >
            <SearchSelector
              allowClear
              handleSearch={debounce(handleSearchClients, 200)}
              loading={loading}
              onChange={e => {
                query();
                handleEmail(e);
              }}
              placeholder="Seleccionar cliente"
            >
              {clients?.data.map(oClient => (
                <Select.Option key={oClient.id} value={oClient.id}>
                  {oClient.invoice_name}
                </Select.Option>
              ))}
            </SearchSelector>
          </Form.Item>
        </Col>

        {sClient ? (
          <Col span={24}>
            <Form.Item
              label="Correo Electronico"
              name="email"
              rules={[{ required: true, message: 'Campo requerido' }]}
            >
              <Input type="text" />
            </Form.Item>
          </Col>
        ) : (
          <></>
        )}

        <Col span={24}>
          <Form.Item label="Forma de Pago" name="payment_form">
            <SearchSelector
              allowClear
              handleSearch={debounce(handleSearchForms, 200)}
              loading={pLoading}
              onChange={() => fQuery()}
              placeholder="Seleccionar Forma de pago"
            >
              {formspays?.data.map(oForm => (
                <Select.Option key={oForm.id} value={oForm.id}>
                  {oForm.code}-{oForm.description}
                </Select.Option>
              ))}
            </SearchSelector>
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item label="Metodo de Pago" name="payment_method">
            <Select loading={mLoading}>
              {methods?.data.map(
                method =>
                  method.status === 1 && (
                    <Select.Option key={method.id} value={method.id}>
                      {method.code} {method.description}
                    </Select.Option>
                  )
              )}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

InvoiceForm.propTypes = {
  formRef: PropTypes.object,
  onSubmit: PropTypes.func,
  setLoading: PropTypes.func,
  oOrder: PropTypes.object,
};
