import React, { useEffect, useState } from 'react';
import { Button, Col, Checkbox, Form, Input, notification, Row } from 'antd';
import {
  UserOutlined,
  LockOutlined,
  LoginOutlined,
  SendOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { getServer, apiKey } from './Utils/url';
import { create } from 'apisauce';
import logo from './Assets/img/logo_factora_blanco.svg';
import { useAuth } from './Hooks/Auth.hook';
const API = create({
  baseURL: getServer(),
});

function App() {
  const [aForget, setForget] = useState(false);
  const [newError, setNewError] = useState(0);
  const [, login, , error, loading] = useAuth();
  const [form] = Form.useForm();
  const key = apiKey();
  const _handleLogin = async values => {
    const status = await login(values.email, values.password);
    if (!status) {
      setNewError(newError + 1);
    }
  };

  useEffect(() => {
    if (error) {
      notification.error({
        message: 'Error',
        description: error || 'Error en petición',
      });
      form.setFieldValue('password', '');
    }
  }, [newError]);

  //Send forgot
  const postData = async ({ email }) => {
    const response = await API.post(
      `/forgot`,
      { email },
      {
        headers: {
          'x-api-key': key,
        },
      }
    );
    if (response?.ok) {
      notification.success({
        description: 'El correo fue enviado',
      });
      setTimeout(() => {
        setForget(false);
      }, 3000);
    } else {
      notification.error({
        message: response.data.message,
        description: 'ocurrio un error',
      });
    }
  };

  return (
    <div className="login-screen">
      <Row>
        <Col span={14} xs={1} md={12} lg={14} className="bg-section">
          <div className="wrapper">
            <div className="bg-image" />
          </div>
        </Col>
        <Col span={10} xs={23} md={12} lg={10} className="login-section">
          <div className="spacer">
            {!aForget ? (
              <Form
                form={form}
                name="normal_login"
                initialValues={{
                  remember: true,
                }}
                onFinish={_handleLogin}
              >
                <div>
                  <Row
                    style={{
                      marginBottom: '20px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Col span={14}>
                      <img src={logo} />
                    </Col>
                  </Row>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: 'Ingresa tu email',
                      },
                    ]}
                  >
                    <Input
                      prefix={<UserOutlined className="site-form-item-icon" />}
                      placeholder="Email"
                      size="large"
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: 'Ingresa tu Password!',
                      },
                    ]}
                  >
                    <Input
                      prefix={<LockOutlined className="site-form-item-icon" />}
                      type="password"
                      placeholder="Password"
                      size="large"
                    />
                  </Form.Item>

                  <Form.Item
                    name="remember"
                    valuePropName="checked"
                    wrapperCol={{
                      span: 4,
                    }}
                  >
                    <Checkbox style={{ color: '#FFF' }}>Recuérdame</Checkbox>
                  </Form.Item>

                  <Form.Item>
                    <Row
                      style={{ flexDirection: 'column-reverse', gap: '5px' }}
                    >
                      <Col span={24}>
                        <Button
                          className="login-form-button"
                          htmlType="submit"
                          icon={<LoginOutlined />}
                          loading={loading}
                          onClick={() => form.submit()}
                          type="primary"
                          size="large"
                        >
                          Acceder
                        </Button>
                      </Col>
                      <Row gutter={[12, 12]}>
                        <Col span={24}>
                          <Button
                            className="login-form-button"
                            style={{
                              background: 'none',
                              border: 'none',
                              textAlign: 'end',
                              color: '#fff',
                            }}
                            type="dashed"
                            icon={<QuestionCircleOutlined />}
                            onClick={() => {
                              setForget(!aForget);
                            }}
                          >
                            Olvidé mi contraseña
                          </Button>
                        </Col>
                      </Row>
                    </Row>
                  </Form.Item>
                </div>
              </Form>
            ) : (
              <Form
                name="normal_login"
                className="login-form"
                initialValues={{
                  remember: true,
                }}
                onFinish={postData}
              >
                <div className="login-form-container">
                  <h2 style={{ color: '#fff' }}>Olvidaste tu contraseña?</h2>
                  <h3 style={{ color: '#fff' }}>
                    ¿No recuerdas tu contraseña? No te preocupes te lo enviamos
                    por correo.
                  </h3>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: 'Ingresa tu correo',
                      },
                    ]}
                  >
                    <Input
                      prefix={<UserOutlined className="site-form-item-icon" />}
                      placeholder="Username"
                      size="large"
                    />
                  </Form.Item>
                  <Form.Item>
                    <Row>
                      <Col span={12}>
                        <Button
                          className="login-form-button"
                          type="dashed"
                          icon={<LoginOutlined />}
                          onClick={() => {
                            setForget(!aForget);
                          }}
                        >
                          Iniciar Sesion
                        </Button>
                      </Col>
                      <Col span={12}>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="login-form-button"
                          icon={<SendOutlined />}
                        >
                          Recuperar Contraseña
                        </Button>
                      </Col>
                    </Row>
                  </Form.Item>
                </div>
              </Form>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default App;
