import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { generateQueries } from '../../Utils/query';
import { process, GET_KEY, FIND } from '../../Service/Api';
import { SearchSelector } from '../Atoms/SearchSelector';
import { useFetchRegimes } from '../../Hooks/Regime.hook';
import { useFetchUsesCFDI } from '../../Hooks/UsesCFDI.hook';
import { Col, Form, Input, Row, Select } from 'antd';
import { useFetchZipcodesWithApiKey } from '../../Hooks/Zipcode.hook';

const RegimeSearch = [
  {
    name: 'description',
    type: 'input',
    whitelist: true,
  },
  {
    name: 'status',
    type: 'select',
  },
];

const UsoSearch = [
  {
    name: 'description',
    type: 'input',
    whitelist: true,
  },
  {
    name: 'status',
    type: 'select',
  },
];

export const ClientForm = ({
  formRef,
  onSubmit,
  onZipCode,
  onIsValid,
  onAddress,
}) => {
  const [usos, uLoading, uQuery] = useFetchUsesCFDI();
  const [regimes, loading, rQuery] = useFetchRegimes();
  const [zipcodes, zLoading, zQuery] = useFetchZipcodesWithApiKey();
  const [selectedZipcode, setSelectedZipcode] = useState('');

  useEffect(() => {
    const fetchZipcodeId = async () => {
      const initialZipcode = formRef.getFieldValue('zipcode');
      if (initialZipcode) {
        try {
          const response = await process(
            FIND,
            'zipcodes',
            {},
            { queries: `codigo=${initialZipcode}&status=1`, skip: 0, limit: 1 }
          );

          if (response.ok && response.data?.data.length > 0) {
            const zipId = response.data.data[0].id;
            setSelectedZipcode(zipId);
          } else {
            console.warn(
              'No se encontró el ID para el código postal:',
              initialZipcode
            );
          }
        } catch (error) {
          console.error('Error al obtener el ID del código postal:', error);
        }
      }
    };

    fetchZipcodeId();
  }, [formRef]);

  const handleSearchZipcodes = s => {
    if (s.length > 4) {
      const sQueries = generateQueries({ codigo: s, status: 1 }, [
        { name: 'codigo', type: 'input-int' },
        { name: 'status', type: 'select' },
      ]);
      zQuery(sQueries, 0, 50);
    } else {
      zQuery();
    }
  };

  const handleSearchRegimes = s => {
    if (s.length > 2) {
      let sQueries = generateQueries(
        { description: s, status: 1 },
        RegimeSearch
      );
      rQuery(sQueries, 0, 50);
    } else {
      rQuery();
    }
  };

  const handleSearchUses = s => {
    if (s.length > 2) {
      let sQueries = generateQueries({ description: s, status: 1 }, UsoSearch);
      uQuery(sQueries, 0, 50);
    } else {
      uQuery();
    }
  };

  const getAddress = async values => {
    let oZipcode = await process(GET_KEY, 'zipcodes', {}, { id: values });

    if (oZipcode.data.codigo !== '') {
      onZipCode(oZipcode.data.codigo);
    }

    if (oZipcode.data.id) {
      onIsValid(true);
      onAddress({
        street: oZipcode.data?.asentamiento,
        neighborhood: oZipcode?.data.asentamiento,
        city: oZipcode.data?.municipio,
        state: oZipcode.data?.estado,
        country: 'Mexico',
        ext: '00',
        inte: '00',
      });
    }
  };

  return (
    <Form
      form={formRef}
      layout="vertical"
      name="ClientForm"
      onFinish={onSubmit}
    >
      <Row gutter={[12, 12]}>
        <Col span={12}>
          <Form.Item
            label="Razon Social"
            name="invoice_name"
            rules={[
              {
                required: true,
                message: 'Campo requerido',
              },
              { max: 50, message: 'Maximo 50 Caracteres' },
            ]}
          >
            <Input type="text" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="RFC"
            name="rfc"
            rules={[
              { required: true, message: 'Campo requerido' },
              { max: 13, message: 'Maximo 13 Caracteres' },
            ]}
          >
            <Input type="text" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Regimen Fiscal"
            name="regime_id"
            rules={[{ required: true, message: 'Campo requerido' }]}
          >
            <SearchSelector
              allowClear
              handleSearch={debounce(handleSearchRegimes, 200)}
              loading={loading}
              onChange={() => rQuery()}
              placeholder="Seleccionar Regimen"
            >
              {regimes?.data.map(
                oRegime =>
                  oRegime.status === 1 && (
                    <Select.Option key={oRegime.id} value={oRegime.id}>
                      {oRegime.description} {oRegime.code}
                    </Select.Option>
                  )
              )}
            </SearchSelector>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Uso de CFDI"
            name="use_id"
            rules={[{ required: true, message: 'Campo requerido' }]}
          >
            <SearchSelector
              allowClear
              handleSearch={debounce(handleSearchUses, 200)}
              loading={uLoading}
              onChange={() => uQuery()}
              placeholder="Seleccionar Uso de CFDI"
            >
              {usos?.data.map(
                oUsos =>
                  oUsos.status === 1 && (
                    <Select.Option key={oUsos.id} value={oUsos.id}>
                      {oUsos.description} {oUsos.code}
                    </Select.Option>
                  )
              )}
            </SearchSelector>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Correo Electronico"
            name="email"
            rules={[
              {
                required: true,
                message: 'Campo requerido',
              },
              { max: 50, message: 'Maximo 50 Caracteres' },
            ]}
          >
            <Input type="text" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Codigo Postal" name="zipcode" required>
            <SearchSelector
              allowClear
              handleSearch={debounce(handleSearchZipcodes, 200)}
              loading={zLoading}
              value={selectedZipcode}
              onChange={e => {
                getAddress(e);
                setSelectedZipcode(e);
              }}
              placeholder="Código Postal"
            >
              {zipcodes?.data.map(
                oCode =>
                  oCode.status === 1 && (
                    <Select.Option key={oCode.id} value={oCode.id}>
                      {oCode.codigo} {oCode.asentamiento}
                    </Select.Option>
                  )
              )}
            </SearchSelector>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[12, 12]}>
        <Col span={12}>
          <Form.Item
            label="Estatus"
            name="status"
            rules={[{ required: true, message: 'Campo requerido' }]}
          >
            <Select>
              <Select.Option value={0}>Inactivo</Select.Option>
              <Select.Option value={1}>Activo</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

ClientForm.propTypes = {
  formRef: PropTypes.object,
  onSubmit: PropTypes.func,
  onZipCode: PropTypes.func,
  onIsValid: PropTypes.func,
  onAddress: PropTypes.func,
};
